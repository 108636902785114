import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import african from "../../../assets/img/banner/african.jpg";
import baby from "../../../assets/img/banner/baby.jpg";

import suitBanner1 from "../../../assets/img/suitBanner1.jpeg";
import suitBanner2 from "../../../assets/img/suitBanner2.jpeg";
import suitBanner3 from "../../../assets/img/suitBanner3.jpeg";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    // getData();
  }, []);

  // Custom Data
  const custom = [
    { id: 1, url: suitBanner1 },
    { id: 2, url: suitBanner2 },
    { id: 3, url: suitBanner3 },
  ];

  return (
    <>
      <section className="bannerSection">
        <div className="">
          <Slider {...settings}>
            {data?.length > 0
              ? data.map((item) => {
                  return (
                    item?.image?.url && (
                      <Link to={item?.url || ""} key={item._id}>
                        <div className="bannerItem">
                          <img src={item?.image.url} className="img-fluid" />
                        </div>
                      </Link>
                    )
                  );
                })
              : custom.map((item) => {
                  return (
                    item?.url && (
                      <Link to={item?.url || ""} key={item.id}>
                        <div className="bannerItem">
                          <img src={item?.url} className="img-fluid" />
                          <div className="bannerContent">
                            <h2>Get the desired hair style you need</h2>
                            <p>
                              Getting ready for an occation or a special event.
                              Wearing hair everyday or only on special days. We
                              have all the answers you need.
                            </p>
                          </div>
                        </div>
                      </Link>
                    )
                  );
                })}
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Banner;
