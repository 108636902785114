import React from "react";
import { Link } from "react-router-dom";

import discover1 from "../../../assets/img/kerastare/discover1.webp";
import discover2 from "../../../assets/img/kerastare/discover2.webp";
import discover3 from "../../../assets/img/kerastare/discover3.webp";

const DiscoverKeras = () => {
  return (
    <>
      <section className="discoverKerasSec">
        <div className="container">
          <div className="discoverInfo row">
            <div className="discoverItem col-md-4">
              <div className="figure">
                <img
                  src={discover2}
                  alt="Discover"
                  className="img-fluid"
                  title="Discover"
                />
              </div>
              <div className="text">
                <h5>
                  Damaged Hair: How Hard Water Secretly Endangers Your Hair
                </h5>
                <p>
                  Water, particularly its calcium content, can wreak havoc on
                  your hair, leading to damaged hair that needs immediate
                  attention.
                </p>
                <Link to="#">learn more</Link>
              </div>
            </div>
            <div className="discoverItem  col-md-4">
              <div className="figure">
                <img
                  src={discover3}
                  alt="Discover"
                  className="img-fluid"
                  title="Discover"
                />
              </div>
              <div className="text">
                <h5>
                  Dry Hair and Dry Hair Ends: Causes, Treatments and Pro Tips
                </h5>
                <p>
                  Dry hair is one of the most common hair complaints for all
                  hair types.
                </p>
                <Link to="#">learn more</Link>
              </div>
            </div>
            <div className="discoverItem  col-md-4">
              <div className="figure">
                <img
                  src={discover1}
                  alt="Discover"
                  className="img-fluid"
                  title="Discover"
                />
              </div>
              <div className="text">
                <h5>
                  What makes Elixir Ultime L'Huile Originale Serum the IT Serum
                  for your hair?
                </h5>
                <p>
                  Hair serums are a game changer for anyone wanting to improve
                  their hair quality and appearance.
                </p>
                <Link to="#">learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiscoverKeras;
