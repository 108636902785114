import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetFeaturedProductQuery } from "../../products/productSlice";
import FeaturedProduct from "./featured-product/FeaturedProduct";
import QuiekViewModal from "../../../pages/QueikViewModal/QuiekViewModal";
import { featuredDB } from "../../../rki-data/category";
import axios from "axios";
import { base_url } from "../../../server";
import { useTranslation } from "react-i18next";

import product1 from "../../../assets/img/kerastare/product1.jpg";
import product2 from "../../../assets/img/kerastare/product2.jpg";
import product3 from "../../../assets/img/kerastare/product3.jpg";
import product4 from "../../../assets/img/kerastare/product4.jpg";
import product5 from "../../../assets/img/kerastare/product5.jpg";
import product6 from "../../../assets/img/kerastare/product6.jpg";
import product7 from "../../../assets/img/kerastare/product7.jpg";
import product8 from "../../../assets/img/kerastare/product8.jpg";
import product9 from "../../../assets/img/kerastare/product9.jpg";
import product10 from "../../../assets/img/kerastare/product10.jpg";
import product11 from "../../../assets/img/kerastare/product11.jpg";

function FeaturedProducts() {
  const [data, setData] = useState();
  const [error, seterror] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const baseUrl = base_url();
  const getdata = async (page) => {
    setisLoading(true);
    try {
      // const res = await axios.get(`https://onlineparttimejobs.in/api/product/page/${page}&${12}`, { withCredentials: true })
      const res = await axios.get(`${baseUrl}product/page/0&10000`, {
        withCredentials: true,
      });
      setData(res.data);
      setisLoading(false);
    } catch (error) {
      alert("Server Error !");
      seterror(true);
      setisLoading(false);
    }
  };
  useEffect(() => {
    getdata(0);
  }, []);
  console.log("data -", data);
  const [modelDataId, setModelDataId] = useState(null);
  const setProduct_id = (id) => {
    setModelDataId(id);
  };

  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id);
    setShow(true);
  };

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  }, [data]);

  // Custom product Data
  const custom = [
    {
      id: 1,
      url: product1,
      title: "discover the new première for all types of damaged hair",
      price: "Rs. 2,199.00 – Rs. 2,499.00",
    },
    {
      id: 2,
      url: product2,
      title: "première decalcifying pre-shampoo concentrate",
      price: "Rs. 1,799.00 – Rs. 2,099.00",
    },
    {
      id: 3,
      url: product3,
      title: "première decalcifying & repairing shampoo",
      price: "Rs. 2,299.00",
    },
    {
      id: 4,
      url: product4,
      title: "discover the new première for all types of damaged hair",
      price: "Rs. 2,299.00",
    },
    {
      id: 5,
      url: product5,
      title: "première decalcifying pre-shampoo concentrate",
      price: "Rs. 2,299.00",
    },
    {
      id: 6,
      url: product6,
      title: "première decalcifying & repairing shampoo",
      price: "Rs. 2,299.00",
    },
    {
      id: 7,
      url: product7,
      title: "discover the new première for all types of damaged hair",
      price: "Rs. 2,299.00",
    },
    {
      id: 8,
      url: product8,
      title: "première decalcifying pre-shampoo concentrate",
      price: "Rs. 2,299.00",
    },
    {
      id: 9,
      url: product9,
      title: "première decalcifying pre-shampoo concentrate",
      price: "Rs. 2,299.00",
    },
    {
      id: 10,
      url: product10,
      title: "discover the new première for all types of damaged hair",
      price: "Rs. 4,29.00",
    },
    {
      id: 11,
      url: product11,
      title: "discover the new première for all types of damaged hair",
      price: "Rs. 1,299.00",
    },
    {
      id: 12,
      url: product5,
      title: "discover the new première for all types of damaged hair",
      price: "Rs. 2,200",
    },
  ];
  // Custom product Data

  return (
    <>
      <section className="productsSection p-30 ">
        <div className="container-fluid">
          <div className="row featuredRow">
            <div className="col-lg-12">
              <div className="fishermanHeader ">
                <div className="fisherman-content">
                  <h3>{t("trending products")}</h3>
                </div>
                <div className="fisherman-btn d-none">
                  <Link to="/products" className="optional-btn">
                    View More
                  </Link>
                </div>
              </div>
            </div>

            <div className="categoryWrapper row">
              {custom.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <div className="featuredInfo col-md-3">
                      <div className="featuredFigure">
                        <div className="featuredImg h-100">
                          <img
                            src={item.url}
                            alt={item.title}
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="featuredContent">
                        <h5 className="mb-2">{item.title}</h5>
                        <p className="mb-0">{item.price}</p>

                        <div className="buyNowInfo">
                          <Link className="btn btn-danger buyNow" to={`#`}>
                            Buy Now
                          </Link>

                          <Link to={`#`} className="buyNow">
                            {t("Add to Cart")}{" "}
                            <i className="ri-arrow-right-up-fill"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            {/* <FeaturedProduct
              data={sortedData}
              getFeaturedPro={getdata}
              isLoading={isLoading}
              error={error}
              handleShow={handleShow}
              setProduct_id={setProduct_id}
            /> */}
          </div>
        </div>

        {/* {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )} */}
      </section>
    </>
  );
}

export default FeaturedProducts;
