import React from "react";
import video1 from "../../../assets/img/video/vd-1.mp4";
import video2 from "../../../assets/img/video/vd-2.mp4";

const Videos = () => {
  return (
    <>
      <section className="videoSec">
        <div className="container ">
          <div className="row">
            <div className="col-md-7">
              <div className="videoDetail">
                <h2>Shop by Hair Concern</h2>
                <p>
                  Getting ready for an occation or a special event. Wearing hair
                  everyday or only on special days. We have all the answers you
                  need.
                </p>
                <div className="whyUse">
                  <h3>Why Kerastare Paris</h3>
                  <ul>
                    <li>Change your hair in 3 minutes</li>
                    <li>Add natural volume to your hair</li>
                    <li>Create natural looking style</li>
                    <li>Feel confident and exoitic</li>
                    <li>Choose your style options</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="videoInfo">
                <video
                  src={video1}
                  width="300"
                  height="300"
                  loop 
                  autoplay="true"
                  muted
                />
                <video
                  src={video2}
                  width="300"
                  height="300"
                  loop
                  autoplay="true"
                  muted
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Videos;
