import React, { useContext, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import ReactImageZoom from "react-image-zoom";
import Slider from "react-slick";

import Review from "./review/Review";
import Breadcrumb from "../../../shared/breadcrumb/Breadcrumb";
import ProductDetailContent from "./product-detail-content/ProductDetailContent";
import SelectImageSlider from "./select-image-slider/SelectImageSlider";
import { BsFillCartFill, BsShareFill } from "react-icons/bs";
import { RiDownloadCloudFill } from "react-icons/ri";

import { productItemContext } from "../../../pages/product-detail";

import "./ProductDetail.css";
import {
  useSetCartMutation,
  setCartLists,
  useOfflineAddPostMutation,
  useSendComboMutation,
  setCartLeng,
} from "../../products/productSlice";
import { useNavigate, useParams } from "react-router-dom";
import { CustomToaster } from "../../../common/toaster/CustomToaster";
import { ImgesData } from "../proImgs/ImgesData";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ModalQuatation from "./ModalQutation";

import strengthens from "../../../assets/img/kerastare/strengthens.webp";
import bain from "../../../assets/img/kerastare/bain.webp";
import oleo from "../../../assets/img/kerastare/oleo.webp";
import KER_BENEFITS from "../../../assets/img/kerastare/KER_BENEFITS.webp";
import maxresdefault from "../../../assets/img/kerastare/maxresdefault.jpg";
import Kerastase_Elixir from "../../../assets/img/kerastare/Kerastase_Elixir.jpg";

// swiper -------------------------------//

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import FeaturedProduct from "../featured-products/featured-product/FeaturedProduct";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { base_url } from "../../../server";
function createMarkup(data) {
  return { __html: data };
}
function ProductDetail() {
  const { t, i18n } = useTranslation();
  const userid = window.localStorage.getItem("user_id");
  const isLogin = window.localStorage.getItem("isLogin");

  const [similorItems, setSimilorItems] = useState([]);
  const [
    addToCart,
    {
      data: datacart,
      isLoading: isAddCartLoading,
      isSuccess: isAddToCartSuccess,
      isError: isAddToCartError,
    },
  ] = useSetCartMutation();
  const [showTaoster, setShowToaster] = useState({
    show: false,
    message: "",
    color: "success",
  });
  const [productAdded, setProductAdded] = useState(false);
  const productData = useContext(productItemContext);
  const [mainImage, setMainImage] = useState(ImgesData[0]);
  const isProductAdded = window.localStorage.getItem("currentProductId");

  const [varint, setVariants] = useState({});
  const params = useParams();
  const [data, setData] = useState(null);
  const [isProduceDetailLoading, setisProduceDetailLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const [productId, setProductId] = useState("");
  const token = window.localStorage.getItem("token");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isloadingVal, setIsloadingVal] = useState(true);
  const sliderRef = useRef();
  const baseUrl = base_url();
  const [filterState, setFilterState] = useState({
    categories: [],
    brands: [],
    minPrice: "",
    maxPrice: "",
    sort: "1",
  });
  const getAllDeta = async () => {
    const userId = userid ? userid : null;
    try {
      const user = await axios.get(`${baseUrl}product/public/${params._id}`, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });

      setData(user.data.product);
      getFilterd(user?.data.product?.category_id[0]?.uid);
      window.localStorage.setItem(
        "sellerId",
        user?.data?.product?.variations[0]?.prices[0]?.seller_id?._id
      );
      window.localStorage.setItem(
        "sku",
        user?.data?.product?.variations[0]?.prices[0]?.sku
      );
      setisProduceDetailLoading(false);
    } catch (error) {
      setisError(true);
      setisProduceDetailLoading(false);
    }
  };

  const getFilterd = async (id) => {
    try {
      const res = await axios.post(
        `${baseUrl}product/filter`,
        { ...filterState, categories: [id] },
        { withCredentials: true }
      );
      setSimilorItems(res.data);
      setIsloadingVal(false);
    } catch (error) {
      alert("Filter Not Apply");
      setIsloadingVal(false);
    }
  };
  useEffect(() => {
    getAllDeta();
    window.localStorage.setItem("variationsId", null);
    console.log(params._id);
    setProductId(params._id);
  }, [params._id]);
  let imgs = mainImage?.url;
  if (data?.getaProduct?.mainimage_url?.url) {
    imgs = data?.getaProduct?.mainimage_url?.url;
  }

  const [zoomImageProps, setzoomImageProps] = useState({
    width: 530,
    height: 450,
    zoomWidth: 500,
    img: imgs,
  });

  const handleUniqueID = (currentElm) => {
    setMainImage(currentElm);
    setzoomImageProps({ ...zoomImageProps, img: currentElm.url });
  };

  const selllerId = window.localStorage.getItem("sellerId");
  let selId = selllerId;

  const handleAddCart = () => {
    const product_count = window.localStorage.getItem("productCount");
    const pickupPoint = window.localStorage.getItem("pickUpPoint");

    const deliveryType = window.localStorage.getItem("deliveryType");
    const product_id = params._id;
    const product_variant = window.localStorage.getItem("variationsId");

    // selId = '651bb0c828441564809cc1d4'
    if (!product_variant) {
      setShowToaster({
        show: true,
        message: "Something went wrong! Select Atleast  1 Variant",
        color: "danger",
      });
    }
    if (pickupPoint == "null") {
      const payload = {
        qty: product_count,
        pickupPoint: pickupPoint,
        variantId: product_variant,
        productId: params._id,
        deliveryType,
        // userid,
        seller_id: selllerId,
        sku: window.localStorage.getItem("SKU"),
      };
      addToCart(payload);
    } else {
      const payload = {
        qty: product_count,
        pickupPoint: pickupPoint,
        variantId: product_variant,
        productId: params._id,
        deliveryType,
        // userid,
        seller_id: selllerId,
        sku: window.localStorage.getItem("SKU"),
      };
      addToCart(payload);
    }
  };

  const handleToaster = () => {
    setShowToaster({ ...showTaoster, show: false });
  };
  const dispacher = useDispatch();

  const [mergsData, setMergsData] = useState(data?.images);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (data) {
      if (data?.isGlobalImage) {
        const clone = {
          width: 530,
          height: 450,
          zoomWidth: 500,
          img: data?.mainImage_url?.url,
        };
        setzoomImageProps(clone);
        setSiliderImage(data?.images);
      } else {
        const clone = {
          width: 530,
          height: 450,
          zoomWidth: 500,
          img: data?.variations[0]?.mainImage_url?.url,
        };
        setzoomImageProps(clone);
        setSiliderImage(data?.variations[0]?.images);
      }
    }
  }, [data]);

  const setSiliderImage = (images) => {
    const cloen = images;
    // const obj = { url: clone?.img };
    // cloen.push(obj);
    setMergsData(cloen);
  };

  useEffect(() => {
    if (isAddToCartSuccess) {
      dispacher(setCartLeng(datacart?.cartLength));
      window.localStorage.setItem("cartItem", datacart?.cart);
      setShowToaster({
        show: true,
        message: "Product added successfully!",
        color: "success",
      });
    }
    if (isAddToCartError) {
      setShowToaster({
        show: true,
        message: "Something went wrong Product Not Add",
        color: "danger",
      });
    }
    if (isProductAdded) {
      setProductAdded(true);
    } else {
      setProductAdded(false);
    }
  }, [isAddToCartSuccess, isAddToCartError, isProductAdded]);

  const shortVariant = (data) => {
    setVariants({ ...data });
  };
  const product_variant = window.localStorage.getItem("variationsId");
  const { updatedProducts: products } = useSelector((state) => {
    return state.productList;
  });
  const [
    postOffline,
    { data: resData, isLoading, isSuccess, isError: offErr },
  ] = useOfflineAddPostMutation();

  const notLogin = (id) => {
    const product_count = window.localStorage.getItem("productCount");
    const pickupPoint = window.localStorage.getItem("pickUpPoint");

    const deliveryType = window.localStorage.getItem("deliveryType");
    const product_variant = window.localStorage.getItem("variationsId");
    if (selllerId === null) {
      selId = "64269f0df127906d53878d3d";
    }
    if (!Object.keys(varint).length) {
      setShowToaster({
        show: true,
        message: "Something went wrong! Select Atleast  1 Variant",
        color: "danger",
      });
    } else {
      if (pickupPoint == "null") {
        postOffline({
          product_count: product_count,
          product_variant: product_variant,
          deliveryType,
          seller_id: selId,
          sku: window.localStorage.getItem("SKU"),
          product_id: params._id,
          pickupPoint: null,
          products: products ? products : "",
          // minimum_order_qty: data?.minimum_order_qty,
          // total_quantity: data?.total_quantity,
        });
      } else {
        postOffline({
          product_count: product_count,
          product_variant: product_variant,
          deliveryType,
          seller_id: selId,
          sku: window.localStorage.getItem("SKU"),
          product_id: params._id,
          pickupPoint: pickupPoint,
          // minimum_order_qty: data?.minimum_order_qty,
          // total_quantity: data?.total_quantity,
        });
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setShowToaster({
        show: true,
        message: "Product added successfully!",
        color: "success",
      });
      dispacher(setCartLists(resData.cart.products));
    }
    if (offErr) {
      setShowToaster({
        show: true,
        message: "Product Not Added!",
        color: "danger",
      });
    }
  }, [isSuccess, offErr]);

  const [varientsval, setVariantsval] = useState(null);

  const navigate = useNavigate();
  const [count1, setCount1] = useState(0);
  const BuyNowItem = (item) => {
    const payload = {
      qty: 1,
      pickupPoint: null,
      variantId: item.variations[count1]?.uid,
      productId: item.uid,
      deliveryType: "HOME DELIVERY",
      seller_id: item.variations[count1]?.prices[0].seller_id?._id,
      sku: item.variations[count1]?.prices[0].sku,
    };
    addToCart(payload);
    setTimeout(() => {
      navigate("/checkout");
    }, 1000);

    setTimeout(() => {
      navigate("/checkout");
    }, 1000);
  };

  const [
    sendCombos,
    { isLoading: isLoadingcomb, isError: isErrorcomb, isSuccess: issusscomb },
  ] = useSendComboMutation();

  const [dilevType, setdilevType] = useState("HOME DELIVERY");
  const homeDelever = (str) => {
    setdilevType(str);
  };

  const sendCombo = (id) => {
    if (dilevType === "HOME DELIVERY") {
      sendCombos({
        userid: userid,
        combo_id: id,
        deliveryType: dilevType,
        pickupPoint: null,
      });
    } else {
      console.log(dilevType);
      sendCombos({
        userid: userid,
        combo_id: id,
        deliveryType: dilevType,
        pickupPoint: null,
      });
      alert("Combo Not Added");
    }
    setTimeout(() => {
      navigate("/cart");
    }, 1000);
  };

  useEffect(() => {
    if (isErrorcomb) {
      alert("Server Error Combo Product Not Add");
    }
    if (issusscomb) {
      alert("Combo Product Add Successfully");
    }
  }, [isErrorcomb, issusscomb]);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const changeImage = (item) => {
    if (item?.images?.length && !data.isGlobalImage) {
      setSiliderImage(item?.images);
      setzoomImageProps({ ...zoomImageProps, img: item.images[0].url });
    }
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const settings2 = {
    speed: 500,
    slidesToShow: 7, // Number of items to show on desktop
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024, // Screen size for tablets
        settings: {
          slidesToShow: 7, // Number of items to show on tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Screen size for mobile devices
        settings: {
          infinite: true,
          slidesToShow: 3, // Number of items to show on mobile
          slidesToScroll: 1,
          // arrows: false,
        },
      },
    ],
  };
  const handleSmallItemClick = (index) => {
    setCurrentSlide(index);
    sliderRef.current.slickGoTo(index);
  };
  return (
    <>
      <Helmet>
        <title>Product Details</title>
        <meta name="keyword" content={"Kerastare Paris"} />
        <meta
          name="description"
          content={`${data?.getaProduct?.meta_description}`}
        />
      </Helmet>
      <Breadcrumb title={data?.getaProduct?.name} show={true} t={t} />
      <ModalQuatation show={show} setShow={setShow} />
      {/* Start Product Details Area */}
      <CustomToaster
        color={showTaoster.color}
        title={data?.getaProduct?.name}
        show={showTaoster.show}
        setShow={handleToaster}
        message={showTaoster.message}
        position="bottom-end"
        delay={10000}
      />
      <section className="product-details-area">
        {/* {isLoading && <div className="preloaderCount">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>} */}
        {/* {isAddCartLoading && <div className="preloaderCount">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>      
        </div>} */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="SelectImageSec d-flex">
                {!isMobile && (
                  <div className="SelectImg">
                    {data && (
                      <SelectImageSlider
                        productData={mergsData}
                        handleUniqueID={handleUniqueID}
                      />
                    )}
                  </div>
                )}

                <div
                  className="product-details-image"
                  style={!isMobile ? { zIndex: "10" } : { zIndex: "0" }}
                >
                  {isMobile && (
                    <div className="mobileBanner">
                      <Slider {...settings} ref={sliderRef}>
                        {!!mergsData?.length &&
                          mergsData.map((item, index) => {
                            return (
                              <div className="image">
                                <img src={item?.url} />
                              </div>
                            );
                          })}
                      </Slider>
                      <div className="small-items">
                        {!!mergsData?.length && (
                          // <SelectImageSlider
                          //   productData={mergsData}
                          //   handleUniqueID={handleSmallItemClick}
                          // />
                          <Slider {...settings2}>
                            {mergsData?.map((item, i) => {
                              return (
                                <div
                                  className="productSliderItem"
                                  key={i}
                                  style={{ marginRight: "10px" }}
                                >
                                  <img
                                    src={item.url}
                                    className="img-fluid"
                                    alt="images"
                                    onClick={() => {
                                      handleSmallItemClick(i);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </Slider>
                        )}
                      </div>
                    </div>
                  )}
                  {!isMobile && zoomImageProps?.img && (
                    <ReactImageZoom
                      // style={{ zIndex: "9999999", position: "relative" }}
                      {...zoomImageProps}
                    />
                  )}

                  <div className="shareProductSec button">
                    <div className="titleText">
                      <BsShareFill />
                      <h6>{t("Share")}</h6>
                    </div>
                    <div className="shareProduct">
                      <ul>
                        <li>
                          <FacebookShareButton url={window.location.href}>
                            <FacebookIcon
                              logofillcolor="white"
                              round={true}
                            ></FacebookIcon>
                          </FacebookShareButton>
                        </li>

                        <li>
                          <WhatsappShareButton url={window.location.href}>
                            <WhatsappIcon
                              logofillcolor="white"
                              round={true}
                            ></WhatsappIcon>
                          </WhatsappShareButton>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {data?.comboDeals?.length > 0 && (
                    <div className="comboShowBox">
                      {data?.comboDeals[0]?.products.map((item, i) => {
                        return (
                          <div>
                            <div className="innerCombo" key={i}>
                              <div>
                                <img
                                  style={{ width: "110px", height: "110px" }}
                                  src={item?.image?.url}
                                  alt="images"
                                />
                              </div>
                              <div>
                                Product Name : {item.name} , variant :{" "}
                                {item?.variant[0].weight}
                              </div>
                            </div>
                            {data?.comboDeals[0]?.products.length === i + 1 ? (
                              <span></span>
                            ) : (
                              <span>+</span>
                            )}
                          </div>
                        );
                      })}
                      <div className="btncomboSec">
                        <div style={{ margin: "10px 0" }}>
                          <h5>
                            Offer Price : {data?.comboDeals[0]?.offer_Price}
                          </h5>
                        </div>
                        <ul
                          className="deliveryModeList storageNumber"
                          id="myTab"
                          role="tablist"
                        >
                          <li role="presentation">
                            <button
                              className={`nac-link`}
                              // className={`nac-link ${!str && 'active'}`}
                              id="homeDelivery-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#homeDelivery"
                              type="button"
                              role="tab"
                              aria-controls="homeDelivery"
                              aria-selected="true"
                              onClick={() => homeDelever("HOME DELIVERY")}
                            >
                              Home Delivery
                            </button>
                          </li>

                          <li role="presentation">
                            <button
                              className={`nac-link`}
                              // className={`nac-link ${!str && 'active'}`}
                              id="pickup-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#pickup"
                              type="button"
                              role="tab"
                              aria-controls="pickup"
                              aria-selected="true"
                              onClick={() => homeDelever("pickuppoint")}
                            >
                              Pickup From Store
                            </button>
                          </li>
                        </ul>
                        <button
                          type="Button"
                          className="default-btn buy"
                          onClick={() => {
                            sendCombo(data?.comboDeals[0]._id);
                          }}
                        >
                          <BsFillCartFill />
                          Buy it now!
                        </button>
                      </div>
                      {isLoadingcomb && (
                        <div className="preloaderCount">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <ProductDetailContent
                t={t}
                count1={count1}
                setCount1={setCount1}
                setVariantsval={setVariantsval}
                getAllDeta={getAllDeta}
                shortVariant={shortVariant}
                value={data}
                data={data}
                wish={data?.wish}
                isError={isError}
                isLoading={isProduceDetailLoading}
                isAddToCartSuccess={isAddToCartSuccess}
                isAddToCartError={isAddToCartError}
                changeImage={changeImage}
                handleShow={handleShow}
                handleAddCart={handleAddCart}
                isAddCartLoading={isAddCartLoading}
                BuyNowItem={BuyNowItem}
              />
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="tab products-details-tab">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ul className="tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          id="description-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#description"
                          type="button"
                          role="tab"
                          aria-controls="description"
                          aria-selected="true"
                        >
                          <div className="dot"></div>
                          {t("Description")}
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="benefits-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#benefits"
                          type="button"
                          role="tab"
                          aria-controls="benefits"
                          aria-selected="false"
                        >
                          <div className="dot"></div>
                          {t("Benefits")}
                        </a>
                      </li>

                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="useWay-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#useWay"
                          type="button"
                          role="tab"
                          aria-controls="useWay"
                          aria-selected="false"
                        >
                          <div className="dot"></div>
                          {t("How to use")}
                        </a>
                      </li>

                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="ingredients-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#ingredients"
                          type="button"
                          role="tab"
                          aria-controls="ingredients"
                          aria-selected="false"
                        >
                          <div className="dot"></div>
                          {t("Ingredients")}
                        </a>
                      </li>

                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="science-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#science"
                          type="button"
                          role="tab"
                          aria-controls="science"
                          aria-selected="false"
                        >
                          <div className="dot"></div>
                          {t("Science")}
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="fragrance-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#fragrance"
                          type="button"
                          role="tab"
                          aria-controls="fragrance"
                          aria-selected="false"
                        >
                          <div className="dot"></div>
                          {t("Fragrance")}
                        </a>
                      </li>

                      {!!data?.video_link && (
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="video-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#video"
                            type="button"
                            role="tab"
                            aria-controls="video"
                            aria-selected="false"
                          >
                            <div className="dot"></div>
                            {t("Video")}
                          </a>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab_content tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <div className="tabs_item">
                          <div
                            className="products-details-tab-content"
                            dangerouslySetInnerHTML={createMarkup(
                              data?.productDescription
                            )}
                          />

                          <div className="productTabDetail row">
                            <div className="col-md-9">
                              <div className="tabContent">
                                <h2>Product Description</h2>
                                <p>
                                  The Kérastase Elixir Ultime serum is a
                                  versatile, leave-in serum for all hair types
                                  seeking nourishment and shine . Infused with a
                                  blend of precious hair oils - Marula oil,
                                  Camellia oil and Argan oil, this hydrating
                                  serum instantly smoothens hair, giving it up
                                  to 96HR frizz control* while also offering
                                  heat protection up to 230 degrees*. Hair feels
                                  soft, radiant and fragrant, without any oil
                                  buildup or a greasy finish.
                                </p>
                                <ul className="moreList">
                                  <li>
                                    *Instrumental test, hair serum Vs non
                                    conditioning shampoo
                                  </li>
                                  <li>Country Of Origin:- Spain.</li>
                                  <li>
                                    Manufacturer & Packer Name and Address:-
                                    L’Oréal S.A, Productos Capilares, Calle
                                    López Bravo, 78, Polígono Industrial
                                    Villalonquéjar, 09001 Burgos, Spain.
                                  </li>
                                  <li>
                                    Importer name and Address:- L'Oréal India
                                    Pvt Ltd, A Wing, 8th Floor, Marathon
                                    Futurex, N M Joshi Marg, Lower Parel,
                                    Mumbai, India, 400013.
                                  </li>
                                  <li>100 ml - Expiry - November 2025</li>
                                  <li>30 ml - Expiry - May 2025</li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="tabImage">
                                <ul>
                                  <li>
                                    <img
                                      src={strengthens}
                                      alt="Strengthens Hair"
                                      className="img-fluid"
                                    />
                                    <p>Strengthens Hair</p>
                                  </li>
                                  <li>
                                    <img
                                      src={oleo}
                                      alt="Heat Protectant"
                                      className="img-fluid"
                                    />
                                    <p>Heat Protectant</p>
                                  </li>
                                  <li>
                                    <img
                                      src={bain}
                                      alt="Nourishing"
                                      className="img-fluid"
                                    />
                                    <p>Nourishing</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab_content tab-pane fade"
                        id="benefits"
                        role="tabpanel"
                        aria-labelledby="benefits-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            <ul className="benefits d-none">
                              <li>
                                <span>
                                  <strong>Brand:</strong>
                                </span>
                                <span>{data?.brand_id?.name}</span>
                              </li>
                              {/* {} */}
                              {data?.isGlobalAttribute
                                ? data?.attributeList.map((item) => {
                                    return (
                                      <li>
                                        <span>
                                          <strong>
                                            {item?.name || "Name"}:
                                          </strong>
                                        </span>
                                        <span>
                                          {item?.list?.map((val, i) => {
                                            return <span>{val.value}</span>;
                                          })}
                                        </span>
                                      </li>
                                    );
                                  })
                                : data?.variations?.map((item) => {
                                    return (
                                      item?.uid === product_variant &&
                                      item.attributeList.map((item2) => {
                                        return (
                                          <li>
                                            <span>
                                              <strong>
                                                {item2?.attributeSetMaster
                                                  ?.name || "Name"}
                                                :
                                              </strong>
                                            </span>
                                            <span>
                                              {item2?.list?.map((val, i) => {
                                                return <span>{val.value}</span>;
                                              })}
                                            </span>
                                          </li>
                                        );
                                      })
                                    );
                                  })}
                            </ul>

                            <div className="productTabDetail row">
                              <div className="col-md-6">
                                <div className="tabContent">
                                  <h2>Product Benefits</h2>
                                  <ul>
                                    <li>✔ Restores hair’s softness</li>
                                    <li>✔ Protects from pillow friction</li>
                                    <li>
                                      ✔ Delivers radiance and shine without a
                                      greasy finish
                                    </li>
                                  </ul>

                                  <ul className="moreList mt-3">
                                    <li>6x more shine*</li>
                                    <li>
                                      Heat protection up to 230 degrees Celsius*
                                    </li>
                                    <li>72hr frizz protection*</li>
                                  </ul>
                                  <em className="fs-5">
                                    *Instrumental test after application of
                                    Elixir Ultime Hair Oil Originale
                                  </em>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="tabImage">
                                  <img
                                    src={KER_BENEFITS}
                                    alt="KER_BENEFITS"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab_content tab-pane fade"
                        id="useWay"
                        role="tabpanel"
                        aria-labelledby="useWay-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            <div className="productTabDetail row">
                              <div className="col-md-6">
                                <div className="tabContent">
                                  <h2>How to Use </h2>
                                  <ul>
                                    <li>
                                      Step 1: Apply 1-2 pumps of the leave-in
                                      hair serum on damp or dry hair. Start from
                                      mid-lengths and work to ends.
                                    </li>
                                    <li>
                                      Step 2: Style your hair as desired after
                                      using the Kérastase Elixir Ultime serum.
                                    </li>
                                  </ul>

                                  <div className="watchway mt-5">
                                    <iframe
                                      width={"100%"}
                                      height={315}
                                      src="https://www.youtube.com/embed/0KbcJpu8q7I?si=ICGiW01vFCHnTSxK"
                                      title="YouTube video player"
                                      frameBorder={0}
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      referrerPolicy="strict-origin-when-cross-origin"
                                      allowFullScreen
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="tabContent">
                                  <h2>Stylist Quote</h2>
                                  <p className="desc">
                                    The uses for this moisture packed oil are
                                    endless! Think of this as pure moisture
                                    without weight as it absorbs fully into the
                                    hair fiber. I love to punch up the
                                    nutritional benefit of any mask with the
                                    addition of the Original Oil. Cleanse hair
                                    with desired shampoo, then squeeze the
                                    excess water out of your hair. Place a half
                                    dollar size amount of desired masque into
                                    palm of hand. Add 2 pumps of Elixir Ultime
                                    Original Oil into palm and mix together.
                                    Apply the newly enhanced mask to hair
                                    beginning at the lengths working the product
                                    from lengths to roots until all hair is
                                    treated. Let it sit for a minimum of 5 mins
                                    up to 20. The result is an indulgent at home
                                    ritual leaving your hair more supple with
                                    beautiful shine!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab_content tab-pane fade"
                        id="ingredients"
                        role="tabpanel"
                        aria-labelledby="ingredients-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            <div className="productTabDetail row">
                              <div className="col-md-6">
                                <div className="tabContent">
                                  <h2>Key Ingredients </h2>
                                  <ul>
                                    <li>
                                      <h5>Marula Oil</h5>
                                      <p>
                                        High in antioxidants, essential fatty
                                        acids, and amino acids, it's effective
                                        at keeping hair soft, supple, and
                                        moisturized.
                                      </p>
                                    </li>
                                    <li>
                                      <h5>Camellia Oil </h5>
                                      <p>
                                        An excellent emollient for keeping hair
                                        moist and supple, it absorbs very
                                        quickly leaving incredibly shiny hair.
                                      </p>
                                    </li>
                                    <li>
                                      <h5>Argan Oil </h5>
                                      <p>
                                        High in antioxidants, essential fatty
                                        acids, and vitamin E, to naturally
                                        increase elasticity, hydrate, soften and
                                        restore shiny hair.
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="tabContent">
                                  <h2>Full Ingredient List</h2>
                                  <p>Cyclopentasiloxane</p>
                                  <ul className="moreList ingredient">
                                    <li>Dimethiconol </li>
                                    <li> Zea Mays </li>
                                    <li> Corn Germ Oil </li>
                                    <li> Argania Spinosa Oil </li>
                                    <li> Argania Spinosa Kernel Oil </li>
                                    <li> Sclerocarya / Birrea Seed Oil </li>
                                    <li> Camellia Oleifera Seed Oil </li>
                                    <li> Caprylic/Capric Triglyceride </li>
                                    <li>
                                      {" "}
                                      Linalool / Pentaclethra Macroloba Seed Oil{" "}
                                    </li>
                                    <li> Phyllanthus Emblica Fruit Extract </li>
                                    <li> Alpha-Isomethyl Ionone </li>
                                    <li> Limonene </li>
                                    <li> Coumarin </li>
                                    <li> Benzyl Alcohol </li>
                                    <li> Tocopherol </li>
                                    <li> Parfum / Fragrance.</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab_content tab-pane fade"
                        id="science"
                        role="tabpanel"
                        aria-labelledby="science-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            <div className="productTabDetail row">
                              <div className="col-md-4">
                                <div className="tabImage">
                                  <img
                                    src={maxresdefault}
                                    alt="maxresdefault"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className="tabContent">
                                  <h2>
                                    Kérastase Decodes The Secret To Optimal
                                    Shine
                                  </h2>
                                  <ul>
                                    <li>
                                      <strong>MIRROR EFFECT</strong>
                                      <p>
                                        Ideally, when light illuminates your
                                        hair, a portion of that light will be
                                        reflected by the fiber surface in a
                                        symmetrical trajectory.
                                      </p>
                                    </li>

                                    <li>
                                      <strong>SURFACE IMPERFECTIONS</strong>
                                      <p>
                                        Can disperse the light, causing it to be
                                        reflected in multiple directions. When
                                        this happens, light loses its intensity,
                                        and our eyes perceive a much weaker
                                        shine.
                                      </p>
                                    </li>
                                    <li>
                                      <strong>NOURISHED HAIR FIBERS</strong>
                                      <p>
                                        Have a smooth, unbroken surface. So, the
                                        more nourished your hair fiber is, the
                                        greater its ability to reflect light.
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab_content tab-pane fade"
                        id="fragrance"
                        role="tabpanel"
                        aria-labelledby="fragrance-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            <div className="productTabDetail row">
                              <div className="col-md-5">
                                <div className="tabImage">
                                  <img src={Kerastase_Elixir} alt="Kerastase_Elixir" className="img-fluid" />
                                </div>
                              </div>
                              <div className="col-md-7">
                                <div className="tabContent">
                                  <h2>Elixir Ultime Addictive Scent</h2>
                                  <p className="desc">
                                    A thoughtfully balanced bouquet of
                                    fragrances for our most iconic hair oil.
                                    Mandarin brings an invigorating zest,
                                    complemented by the gentle notes of freesia,
                                    creating an atmosphere of classic
                                    sophistication. Subtle violet leaves add a
                                    soothing, unobtrusive layer, leaving a
                                    delicate, memorable impression that
                                    accompanies you throughout the day.
                                  </p>
                                  <em>
                                    MORE INFORMATION ON THE FRAGRANCE OF THIS
                                    PRODUCT
                                  </em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab_content tab-pane fade"
                        id="video"
                        role="tabpanel"
                        aria-labelledby="video-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            {/* <video width="400" controls>
                              <source src={`${data?.video_link}`} type="video/mp4" />
                              <source src={`${data?.getaProduct?.video_link}`} type="video/ogg"/>
                              Your browser does not support HTML video.
                            </video> */}
                            <ReactPlayer
                              url={data?.video_link} // Replace with your video URL
                              controls={true}
                              width="100%"
                              height="auto"
                            />
                            {/*                             
                            <iframe width="560" height="315" src={data?.video_link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div style={{ margin: "20px 0" }}>
                <h5>Meta Title : {data?.getaProduct?.meta_title} </h5>
                <h5>Meta Description : {data?.getaProduct?.meta_description}</h5>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="productsSection p-30">
        <div className="container">
          <div className="row featuredRow">
            <div className="col-lg-12">
              <div className="fishermanHeader ">
                <div className="fisherman-content">
                  <h3>{t("Similar items")}</h3>
                  <p></p>
                </div>
                {/* <div className="fisherman-btn d-none">
                  <Link to="/products" className="optional-btn">
                    View More
                  </Link>
                </div> */}
              </div>
            </div>
            <FeaturedProduct
              data={similorItems}
              isLoading={isloadingVal}
              handleShow={handleShow}
            />
          </div>
        </div>

        {/* {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )} */}
      </section>

      <Review ids={params._id} />
      {/* <Review ids={window.localStorage.getItem('variant_id')} /> */}
      {/* {data && <RelatedProducts productData={data} />} */}
      {/* End Product Details Area */}
    </>
  );
}

export default ProductDetail;
